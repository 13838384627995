<template>
  <div class="report_data_details" v-if="isShow">
    <div class="one">
      <div class="report_data_details_from">
        <el-form
          :model="queryInfo"
          class="product_form"
          label-width="100px"
          :rules="rules"
          ref="ruleForm"
        >
          <!-- 新增报告类型 -->
          <el-form-item label="报告类型:">
            <el-radio-group v-model="queryInfo.type" @input="changeType">
              <el-radio label="单份报告">单份报告</el-radio>
              <el-radio label="报告合集">报告合集</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="报告类别:" prop="reportType">
            <dic-port-reout :code.sync="queryInfo.reportType" type-code="009" />
          </el-form-item>
          <el-form-item label="报告图片:" prop="pdfImage">
            <UpLoadImage
              @upLoadImgList="clickTu"
              @upOldLoadImgList="clickOldTu"
              :upLoadImgList.sync="queryInfo.pdfImage"
              :upOldLoadImgList.sync="queryInfo.pdfOldImage"
            />
            <span class="tips"
              >(建议整体尺寸为900*274px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span
            >
          </el-form-item>
          <el-form-item label="报告名称:" prop="reportName">
            <el-input
              v-model="queryInfo.reportName"
              placeholder="请输入"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="一句话简介:" prop="reportBrief">
            <el-input
              v-model="queryInfo.reportBrief"
              placeholder="请输入"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="封面图:" prop="coverImage">
            <UpLoadImage
              @upLoadImgList="clickCover"
              @upOldLoadImgList="clickOldCover"
              :upLoadImgList.sync="queryInfo.coverImage"
              :upOldLoadImgList.sync="queryInfo.coverOldImage"
            />
            <span class="tips"
              >(建议整体尺寸为900*274px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span
            >
          </el-form-item>
          <el-form-item label="网盘链接:" prop="networkStorageLink">
            <el-input
              v-model="queryInfo.networkStorageLink"
              placeholder="请输入"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="提取码:" prop="fetchCode">
            <el-input
              v-model="queryInfo.fetchCode"
              placeholder="请输入"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="报告页数:" prop="reportPages">
            <el-input
              v-model="queryInfo.reportPages"
              placeholder="请输入"
              style="width: 50%"
            />
          </el-form-item>
          <div
            v-show="typeTwo"
            class="collection"
            v-for="(item, index) in queryInfo.reportCollectionResults"
            :key="index + 'res'"
            style="width: 100%"
          >
            <el-form-item label="报告合集:" prop="reportCollectionResults">
              <div style="display: flex; width: 100%">
                <div
                  style="
                    width: 50%;
                    border: 0.5px solid #dbdbdb;
                    padding: 10px 10px 10px 0;
                    border-radius: 5px;
                    box-sizing: border-box; ;
                  "
                >
                  <el-form-item
                    label="附件名称:"
                    prop="fileNameCollect"
                    style="margin-bottom: 10px"
                  >
                    <el-input
                      v-model="item.fileNameCollect"
                      placeholder="请输入"
                      style="width: 100%"
                    />
                    <!-- <el-input
                      v-model="item.fileNameCollect"
                      readonly
                      style="width: 100%"
                    />
                    <myUploadReportFile
                      :index="index"
                      @upFileKey="fileKeyCollect"
                      @fileSucee="successUploadCollect"
                    /> -->
                  </el-form-item>
                  <el-form-item label="发布日期:" prop="releaseTime">
                    <el-input
                      v-model="item.releaseTime"
                      placeholder="请输入"
                      style="width: 100%"
                    />
                  </el-form-item>
                </div>
                <span style="color: #4e93fb" @click="addReport(item)">
                  <i
                    class="el-icon-circle-plus-outline"
                    style="font-size: 31px; cursor: pointer; margin-left: 10px"
                  ></i>
                </span>
                <span v-if="index > 0" @click="delReport(index)">
                  <i
                    class="el-icon-remove-outline"
                    style="
                      color: #ff7575;
                      font-size: 31px;
                      cursor: pointer;
                      margin-left: 10px;
                    "
                  ></i>
                </span>
              </div>
            </el-form-item>
          </div>
          <el-form-item label="发布日期:" prop="reportTime">
            <el-input
              v-model="queryInfo.reportTime"
              placeholder="请按照1999-01-01格式填写"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="发布机构:" prop="reportManager">
            <el-input
              v-model="queryInfo.reportManager"
              placeholder="请输入"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="报告附件:" prop="fileName" class="fileName">
            <el-input
              v-model="queryInfo.fileName"
              readonly
              style="width: 50%"
            />
            <upload-report-file
              @upFileKey="fileKey"
              @fileSucee="successUpload"
            />
          </el-form-item>
          <el-form-item label="报告简介:" prop="reportIntro">
            <!-- <el-input
              v-model="queryInfo.reportIntro"
              placeholder="请输入"
              type="textarea"
              style="width: 50%"
              :autosize="{ minRows: 4, maxRows: 6 }"
            /> -->
            <Editorbar
              v-model="queryInfo.reportIntro"
              :isClear="isClear"
              style="width: 50%"
            />
          </el-form-item>

          <!-- ============ -->
          <!-- <el-form-item label="行业类别:" prop="reportTags">
            <dic-port-details
              :code.sync="queryInfo.reportTags"
              tag="行业"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="领域类别:" prop="fieldId">
            <dic-port-details
              :code.sync="queryInfo.fieldId"
              tag="领域"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="领域标签:" v-if="ReportDataType">
            <el-input
              v-model="queryInfo.wisdomTag"
              style="width: 80%"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="行业标签:" v-if="ReportDataType">
            <el-input
              v-model="queryInfo.IndustryTag"
              style="width: 80%"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="关键词:"
            v-if="ReportDataType"
            label-width="100px"
          >
            <el-input
              v-model="queryInfo.antistop"
              style="width: 80%"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <div class="info_user" v-if="this.reportDataType">
        <div class="ess_people_list">创建人：{{ queryInfo.createName }}</div>
        <div class="ess_people_list">修改人：{{ queryInfo.updateName }}</div>
        <div class="ess_people_list">更新时间：{{ queryInfo.updateTime }}</div>
        <div class="ess_people_list">收录时间：{{ queryInfo.createTime }}</div>
      </div>
    </div>
    <!-- <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelClick">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import DicPortDetails from "../com/dicPortDetails.vue";
import DicPortReout from "../com/dicPortReout.vue";
import UploadReportFile from "../com/uploadReportFile.vue";
import UpLoadImage from "../UpLoadImage";
import myUploadReportFile from "../com/myUploadReportFile.vue";
import Editorbar from "@/components/Editorbar";
//  报告基本信息详情
import { getReportDataDetails } from "@/api/report";
import {
  addNewPortData,
  getSingleReort,
  updatePortData,
} from "../../../api/report";
import Vue from "vue";

export default {
  components: {
    UploadReportFile,
    DicPortDetails,
    DicPortReout,
    UpLoadImage,
    myUploadReportFile,
    Editorbar,
  },
  name: "ReportDataDetails",
  data() {
    var regex = (rule, value, callback) => {
      var regex = /^\d{4}-\d{2}-\d{2}$/;
      if (regex.test(value)) {
        callback();
      } else {
        callback(new Error("日期格式不对"));
      }
    };

    return {
      isClear: false,
      isShow: false,
      reportId: "", //数据唯一id
      typeTwo: false, //单份报告(fasle) 报告合集(true)
      queryInfo: {
        reportName: "", //报告名称
        reportKey: "", //(报告附件/文件名)文件上传返回的参数
        reportKeyCollect: "", //(报告附件/文件名)文件上传返回的参数
        createName: "",
        updateName: "",
        updateTime: "",
        creationTime: "",
        reportTags: [], //行业类别
        reportTagsChild: [],
        fieldId: [], // 领域类别
        fieldIdChild: [],
        reportType: "", //报告类别
        type: "", //报告类型: 单份报告，集合报告
        pdfImage: [], //报告图片
        pdfOldImage: [],
        reportBrief: "", //报告一句话介绍
        coverImage: [], //报告封面
        coverOldImage: [],
        networkStorageLink: "", //网盘链接
        fetchCode: "", //提取码
        reportPages: null, //报告篇数/报告页数
        reportTime: "", //出版时间/发布日期
        reportManager: "", //出版机构名称/发布机构
        fileName: "", //报告附件/文件名
        reportIntro: "", //报告简介
        reportCollectionResults: [
          //报告合集参数
          {
            fileNameCollect: "", //附件名称
            releaseTime: "", //发布时间
          },
        ],
      },

      rules: {
        reportName: [
          { required: true, message: "请输入报告名称", trigger: "blur" },
        ],
        fileName: [
          {
            required: true,
            message: "请上传报告附件",
            trigger: "change",
          },
        ],
        reportTime: [
          {
            required: true,
            message: "请按照格式填写日期",
            trigger: "blur",
            validator: regex,
          },
        ],
        // releaseTime: [
        //   {
        //     required: true,
        //     message: "请按照格式填写日期",
        //     trigger: "blur",
        //     validator: regex,
        //   },
        // ],
        // reportType: [
        //   {
        //     required: true,
        //     message: "请至少选择一个报告类型",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  watch: {
    // 监听报告类型是否是报告合集,来控制报告合集的展示
    "queryInfo.type"(newVal) {
      this.typeTwo = newVal == "报告合集" ? true : false;
    },
  },
  async created() {
    if (this.reportDataType) {
      this.reportId = this.$route.query.reportId;
      this.getReportDataDetails();
      // this.getOnePortData();
    }
    setTimeout(() => {
      this.isShow = true;
    }, 300);
  },
  computed: {
    // 0 新增 1 编辑
    reportDataType() {
      return this.$route.query.ReportDataType;
    },
    // // 发布机构
    // reportManager() {
    //   return this.$route.query.reportManager;
    // },
    // reportManager() {
    //   return this.$route.query.reportManager;
    // },
  },
  methods: {
    // 报告基本信息详情
    async getReportDataDetails() {
      const res = await getReportDataDetails({
        reportId: this.reportId,
      });
      if (res.code == 200) {
        this.queryInfo = res.data;
      }
    },
    //上传组件图片地址（封面图）
    clickOldTu(val) {
      this.queryInfo.pdfImage = val;
    },
    clickTu(val) {
      this.queryInfo.pdfOldImage = val;
    },
    //上传组件图片地址（报告封面）
    clickOldCover(val) {
      this.queryInfo.coverImage = val;
    },
    clickCover(val) {
      this.queryInfo.coverOldImage = val;
    },
    //(报告附件/文件名)文件上传成功返回的名字
    successUpload(val) {
      this.queryInfo.fileName = val;
    },
    //(报告附件/文件名)文件上传返回的参数
    fileKey(val) {
      this.queryInfo.reportKey = val;
    },
    //(报告合集-附件名称)文件上传成功返回的名字
    successUploadCollect(val, index) {
      this.queryInfo.reportCollectionResults[index].fileNameCollect = val;
    },
    //(报告附件/文件名)文件上传返回的参数
    fileKeyCollect(val, index) {
      this.queryInfo.reportKey = val;
    },
    // 添加标签内容等四个字段
    addReport(item) {
      this.queryInfo.reportCollectionResults.push({
        fileNameCollect: "", //附件名称
        releaseTime: "", //发布时间
      });
    },
    delReport(index) {
      this.queryInfo.reportCollectionResults.splice(index, 1);
    },
    // 根据报告类型来展不展示报告合集
    changeType(val) {
      this.typeTwo = val == "报告合集" ? true : false;
    },
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    // -----------------
    //查询单个报告数据
    async getOnePortData() {
      const res = await getSingleReort({ reportId: this.reportId });
      if (res.code == 200) {
        Object.assign(this.queryInfo, res.data);
        //行业类别转数组
        this.queryInfo.reportTags = this.formatString(
          this.queryInfo.reportTags
        );
        //领域类别转数组
        this.queryInfo.fieldId = this.formatString(this.queryInfo.fieldId);
      }
    },
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    updateCode(val, tag) {
      switch (tag) {
        case "行业":
          this.queryInfo.reportTagsChild = val;
          break;

        case "领域":
          this.queryInfo.fieldIdChild = val;
          break;

        default:
          break;
      }
    },
    //保存数据
    // saveItem() {
    //   if (this.ReportDataType) {
    //     this.$refs.ruleForm.validate(async (valid) => {
    //       if (valid) {
    //         //行业
    //         if (this.queryInfo.reportTagsChild) {
    //           this.queryInfo.reportTags = this.queryInfo.reportTags.concat(
    //             this.queryInfo.reportTagsChild
    //           );
    //         }
    //         //领域
    //         if (this.queryInfo.fieldIdChild) {
    //           this.queryInfo.fieldId = this.queryInfo.fieldId.concat(
    //             this.queryInfo.fieldIdChild
    //           );
    //         }
    //         const res = await updatePortData(this.queryInfo);
    //         if (res.code == 200) {
    //           this.$message.success("修改成功");
    //           this.$router.go(-1);
    //           // if (this.reportManager) {
    //           //   this.$router.push({
    //           //     name: 'ReportingNumber',
    //           //     params: {
    //           //       reportManager: this.reportManager,
    //           //     },
    //           //   });
    //           // }
    //         } else {
    //           this.$message.error(res.msg);
    //         }
    //       } else {
    //         this.$message.error("请完善基本信息!");
    //       }
    //     });
    //   } else {
    //     this.$refs.ruleForm.validate(async (valid) => {
    //       if (valid) {
    //         //行业
    //         if (this.queryInfo.reportTagsChild) {
    //           this.queryInfo.reportTags = this.queryInfo.reportTags.concat(
    //             this.queryInfo.reportTagsChild
    //           );
    //         }
    //         //领域
    //         if (this.queryInfo.fieldIdChild) {
    //           this.queryInfo.fieldId = this.queryInfo.fieldId.concat(
    //             this.queryInfo.fieldIdChild
    //           );
    //         }
    //         const res = await addNewPortData(this.queryInfo);
    //         if (res.code == 200) {
    //           this.$message.success("新增成功");
    //           this.$router.go(-1);
    //         } else {
    //           this.$message.error(res.msg);
    //         }
    //       } else {
    //         this.$message.error("请完善基本信息!");
    //       }
    //     });
    //   }
    // },
  },
  // mounted() {
  //   document.getElementsByClassName("el-tabs__content")[0].style.height =
  //     document.body.clientHeight - 242 + "px";
  // },
};
</script>

<style lang='less' scoped>
.one {
  overflow: auto;
  background: white;
}
.report_data_details {
  font-family: PingFang SC;
  width: 100%;
  overflow: auto;
  .report_data_details_from {
    width: 100%;
    overflow: auto;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 20px;
  }

  .info_user {
    display: flex;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 16px 20px;
    background-color: #fff;
    flex-direction: column;
    overflow: hidden;
    align-content: center;

    .ess_people_list {
      width: 100%;
      margin: 4px 0;
      color: #7c7f8e;
      font-size: 12px;
    }
  }

  .bottom {
    border-top: 1px solid #dbdbdb;
    background: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    .el-button {
      margin: 0 20px;
    }
  }
  .collection {
    width: 70%;
  }
  .tips {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    color: #1e9fff;
  }
}
.fileName {
  /deep/.el-form-item__content {
    position: relative;
    display: flex;
    :nth-child(2) {
      position: relative;
      left: -94px;
    }
  }
  /deep/.upload-demo {
    margin-left: 10px;
  }
}
.collection {
  /deep/.el-form-item__content {
    position: relative;
    display: flex;
  }
  /deep/.upload-demo {
    margin-left: 10px;
  }
  /deep/.el-form-item {
    width: 100%;
  }
}
</style>
