<template>
  <div>
    <div class="tabIndex">
      <div class="tabIndex_type">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <ReportDataDetails ref="reportDataDetails" :reportId="reportId" />
          </el-tab-pane>
          <el-tab-pane label="标签信息" name="second">
            <label-info
              ref="labelInfo"
              :reportDataType="reportDataType"
            ></label-info>
          </el-tab-pane>
          <el-tab-pane label="手动标签" name="third">
            <labelManagement
              ref="labelManagement"
              :reportDataType="reportDataType"
              :companyId="String(saveReportId)"
              :companyCode="String(reportId)"
            ></labelManagement>
          </el-tab-pane>
          <el-tab-pane label="半自动化标签" name="four">
            <robotizationlabel
              ref="robotizationlabel"
              :companyId="String(saveReportId)"
              :companyCode="String(reportId)"
            ></robotizationlabel>
          </el-tab-pane>
        </el-tabs>
        <div
          style="height: 50px; background: #fff; border-top: 1px solid #dbdbdb;"
          v-if="this.activeName == 'four'"
        ></div>
        <div class="bottom" v-if="activeName !== 'four'">
          <el-button type="primary" @click="saveItem">保存</el-button>
          <el-button @click="cancelItem">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 新增报告基本信息 / 编辑报告基本信息 / 新增报告标签信息 / 编辑报告标签信息 / 查询基本信息详情 / 编辑/保存手动标签关键字
import {
  insertReportInfo,
  editReportDetaDetails,
  insertReportKeywordLabel,
  editReportKeywordLabel,
  getReportKeywordLabel,
  insertHandLabel,
} from "@/api/report.js";
import { savelabel, updateInformationLabel } from "@/api/datement.js";
import ReportDataDetails from "./ReportDataDetails.vue";
import labelInfo from "./labelInfo.vue";
import labelManagement from "./labelManagement.vue";
import robotizationlabel from "./robotizationlabel.vue";
// 引入子组件
export default {
  name: "tabIndexReportData",
  components: {
    ReportDataDetails,
    labelInfo,
    labelManagement,
    robotizationlabel,
  },
  data() {
    return {
      activeName: "first", // 当前标签页
      saveReportId: "", //保存完基本信息的唯一id
    };
  },
  created() {
    // 如果是编辑，就将父页面传递的表格序号赋值给productCode
    if (this.reportDataType) {
      this.getReportKeywordLabel();
      // this.productCode = this.$route.query.productCode;
      // this.seach();
      // this.selectProductLabel();
    }
  },
  watch: {
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "third") {
          this.$refs.labelManagement.getBylabelId();
        }
      },
    },
  },
  computed: {
    // 0 新增 1 编辑 点击新增/编辑页面按钮调整路径带过来的
    reportDataType() {
      return Number(this.$route.query.ReportDataType);
    },
    // 数据唯一id
    reportId() {
      return Number(this.$route.query.reportId);
    },
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      if (tab.index == 2 && (this.companyId || this.companyCode)) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    // 保存
    saveItem() {
      let res;
      switch (this.activeName) {
        case "first":
          this.$refs.reportDataDetails.$refs.ruleForm.validate(
            async (valid) => {
              if (valid) {
                // 存在是编辑
                if (this.reportDataType || this.saveReportId) {
                  res = await editReportDetaDetails(
                    this.$refs.reportDataDetails.queryInfo
                  );
                  if (res.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "second";
                  }
                } else {
                  res = await insertReportInfo(
                    this.$refs.reportDataDetails.queryInfo
                  );
                  if (res.code == 200) {
                    // 请求成功后返回唯一id
                    this.saveReportId = res.data;
                    this.$refs.reportDataDetails.queryInfo.reportId = res.data;
                    this.$refs.robotizationlabel.companyCodeSave = res.data;
                    this.$message.success("新增成功");
                    this.activeName = "second";
                  }
                }
              } else {
                this.$message.error("请先完善基本信息");
              }
            }
          );
          break;
        case "second":
          //最终组装的数组
          let arr = [];
          //开始添加
          this.$refs.labelInfo.reportKeywordLabelResultParam.reportKeywordAddParamList.forEach(
            (item) => {
              let keyword = item.keyword;
              let keywordDescribe = item.keywordDescribe;
              let reportLabelList = [];
              // 遍历我添加的数组
              item.reportLabelList.forEach((child) => {
                // 遍历总数组
                this.$refs.labelInfo.labelOptions.forEach((item1) => {
                  if (item1.labelKeywordResultList[0].id == child.labelId) {
                    item1.labelKeywordResultList.forEach((name) => {
                      name = {
                        groupLabelId: name.groupLabelPrefix,
                        groupLabelName: name.groupLabelType,
                        labelId: name.id,
                        labelName: name.labelName,
                        labelStatus:
                          item1.labelKeywordResultList[0].id == name.id ? 1 : 0,
                      };
                      reportLabelList.push(name);
                    });
                  }
                });
              });

              let all = {
                keyword: keyword,
                keywordDescribe: keywordDescribe,
                reportLabelList: reportLabelList,
              };
              arr.push(all);
            }
          );

          // 最终提交后端的数据
          let reportKeywordLabelResultParam = {
            keywordVersion:
              this.$refs.labelInfo.reportKeywordLabelResultParam.keywordVersion, //关键字版本
            reportId: this.saveReportId ? this.saveReportId : this.reportId, //产品主键
            reportKeywordAddParamList: arr,
          };
          // 判断是编辑还是新增 1编辑  0新增
          if (!this.reportId) {
            // 如果未保存基本信息
            if (!this.saveReportId) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              this.$refs.labelInfo.$refs.labelInfoForm.validate(
                async (valid) => {
                  if (valid) {
                    res = await insertReportKeywordLabel(
                      reportKeywordLabelResultParam
                    );
                    if (res.code == 200) {
                      this.$message.success("新增成功!");
                      this.activeName = "third";
                    } else {
                      this.$message.error("新增失败，请稍候重试!");
                    }
                  }
                }
              );
            }
          } else {
            this.$refs.labelInfo.$refs.labelInfoForm.validate(async (valid) => {
              if (valid) {
                res = await editReportKeywordLabel(
                  reportKeywordLabelResultParam
                );
                if (res.code == 200) {
                  this.$message.success("编辑成功!");
                  this.activeName = "third";
                } else {
                  this.$message.error("编辑失败，请稍候重试!");
                }
              }
            });
          }
          break;
        case "third":
          // 如果是编辑页面
          if (this.reportDataType) {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach((el) => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.companyId = this.reportId
                  ? this.reportId
                  : this.saveReportId;
                query.push(el.labelparam);
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertHandLabel(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    // this.$router.go(-1);
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                insertHandLabel(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    // this.$router.go(-1);
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
            return;
          }
          if (!this.saveReportId) {
            this.$message.error("请先保存基本信息的数据再进行下一步操作！");
          } else {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach((el) => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.companyId = this.reportId
                  ? this.reportId
                  : this.saveReportId;
                query.push(el.labelparam);
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertHandLabel(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    // this.$router.go(-1);
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                insertHandLabel(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    // this.$router.go(-1);
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          }

          break;
        default:
          break;
      }
    },
    //取消
    cancelItem() {
      this.$router.push({
        path: "reportData",
      });
    },
    // 报告标签信息详情
    async getReportKeywordLabel() {
      const res = await getReportKeywordLabel({
        reportId: this.reportId,
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.reportKeywordAddParamList) {
          this.$refs.labelInfo.reportKeywordLabelResultParam = {
            reportId: "", // 产品主键
            keywordVersion: "", // 关键字版本
            // type: null,
            reportKeywordAddParamList: [
              {
                reportLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    reportKeywordId: null,
                    id: null,
                    superiorLabel: null, // 上级标签
                  },
                ],
                keyword: "", // 关键词
                keywordDescribe: "", // 关键词数据
              },
            ],
          };
        } else {
          this.$refs.labelInfo.reportKeywordLabelResultParam = res.data;
          // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
          res.data.reportKeywordAddParamList.forEach((item) => {
            item.reportLabelList.forEach((child) => {
              child.labelName = child.groupLabelName + "/" + child.labelName;
            });
          });
        }
      }
    },
    // 查询
    // async seach() {
    //   // 参数-表格序号
    //   const queryInfo = {
    //     productCode: this.productCode,
    //   };
    //   const res = await getFindOne(queryInfo);
    //   if (res.code == 200) {
    // },
  },
};
</script>

<style lang="less" scoped>
.tabIndex_type {
  padding: 0 20px;
}
.tabIndex {
  overflow: hidden;
  background-color: #fff;
  /deep/ .el-tabs__content {
    overflow: auto;
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
