import { render, staticRenderFns } from "./myUploadReportFile.vue?vue&type=template&id=37cdae95&scoped=true&"
import script from "./myUploadReportFile.vue?vue&type=script&lang=js&"
export * from "./myUploadReportFile.vue?vue&type=script&lang=js&"
import style0 from "./myUploadReportFile.vue?vue&type=style&index=0&id=37cdae95&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37cdae95",
  null
  
)

export default component.exports